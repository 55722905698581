// import isPlainObject from 'lodash.isplainobject';
import * as R from 'ramda';

import * as JSZip from 'jszip';

import {
  emailRegex,
  mobileNumberRegex,
  personalNumberRegex,
  eIDASPersonIdRegex,
  employeeNumberRegex,
  orgAffiliationRegex,
  hsaIdRegex,
} from './regex';

// export const isNonPrimitive = (x) => Array.isArray(x) || isPlainObject(x);

// These are empty immutable singletons for some non-primitive data types.
// Solve react hook dependency difficulty.
export const ARR = Object.freeze([]);
export const OBJ = Object.freeze(Object.create(null));
export const FUNC = Function.prototype;

export const getFileName = (path) => {
  const regex = /[0-9]{2}[.][0-9]{5,}Z-(.*)/;
  const fileRegexp = regex.exec(path);
  const fileName = fileRegexp?.[1] ?? '';
  return fileName;
};

// export const toEscapedText = (msg = '') => {
//   // Escape special characters
//   const escaped = JSON.stringify(msg);
//   // Remove quotes in beginning and end
//   return escaped.slice(1, escaped.length - 1);
// };

export const removeAllWhitespace = (s) => s.replace(/ /g, '').trim();

const isValidRegex = (personalNumber) => !!personalNumber.match(personalNumberRegex);

const isDate = (year, month, day) => {
  const checkDate = new Date(year, month, day);
  if (
    year === checkDate.getFullYear() &&
    parseInt(month, 10) === checkDate.getMonth() &&
    parseInt(day, 10) === checkDate.getDate()
  )
    return true;
  return false;
};

export const validatePersonalNumber = (personalNumber) => {
  // Check regex structure
  if (!isValidRegex(personalNumber)) {
    return false;
  }

  const checkNummer = personalNumber.replace('-', '');

  // Check that every date is valid
  try {
    if (
      !isDate(
        parseInt(checkNummer.substring(0, 4), 10),
        parseInt(checkNummer.substring(4, 6), 10) - 1,
        parseInt(checkNummer.substring(6, 8), 10),
      )
    ) {
      return false;
    }
  } catch (e) {
    return false;
  }

  // eslint-disable-next-line fp/no-let
  let checkSum = 0;

  [...Array(10).keys()].forEach((i) => {
    const index = i + 2;
    const n = parseInt(checkNummer[index], 10);
    if (index % 2 === 0) {
      // eslint-disable-next-line fp/no-mutation
      checkSum += ((n * 2) % 9) + Math.floor(n / 9) * 9;
    } else {
      // eslint-disable-next-line fp/no-mutation
      checkSum += n;
    }
  });

  if (checkSum % 10 === 0) {
    return true;
  }
  return false;
};

/*
   TODO: add further validation for personal number and mobil.
   Validation for eIDASPersonIdentifier and eIDASProvisionalIdentifier are left 'unspecified' for now on backends request
*/
const urnoidToPropFunc = {
  'urn:oid:1.2.752.29.4.13': (t) => ({
    type: 'urn:oid:1.2.752.29.4.13',
    id: 'personalNumber',
    label: t('Personal number'),
    helperText: t('Swedish personal ID number. 12 digits without spaces and hyphens.'),
    tokenAttribute: 'personalID',
    validation: {
      required: t('Personal number is required'),
      pattern: {
        value: personalNumberRegex,
        message: t('Submit a valid personal number'),
      },
      validate: {
        // Passing a function allows us to pass an error message
        validate: (n) => validatePersonalNumber(n) || t('Submit a valid personal number'),
      },
    },
    trim: removeAllWhitespace,
    props: {
      required: true,
    },
  }),
  'urn:oid:1.2.752.201.3.7': (t) => ({
    type: 'urn:oid:1.2.752.201.3.7',
    id: 'eIDASPersonId',
    label: t('eIDAS Person Identifier'),
    helperText: '',
    tokenAttribute: 'eIDASPersonIdentifier',
    validation: {
      required: t('This field is required'),
      pattern: {
        value: eIDASPersonIdRegex,
        message: t('Submit a valid eIDAS id'),
      },
    },
    trim: removeAllWhitespace,
    props: {
      required: true,
    },
  }),
  'urn:oid:1.2.752.201.3.4': (t) => ({
    type: 'urn:oid:1.2.752.201.3.4',
    id: 'eIDASProvisionalId',
    label: t('eIDAS Provisional Identifier'),
    helperText: '',
    tokenAttribute: 'eIDASProvisionalID',
    validation: { required: 'This field is required' },
    trim: removeAllWhitespace,
    props: {
      required: true,
    },
  }),
  'urn:oid:0.9.2342.19200300.100.1.41': (t) => ({
    type: 'urn:oid:0.9.2342.19200300.100.1.41',
    id: 'mobileNumber',
    label: t('Mobile number'),
    helperText: t('A mobile number, including country code. E.g: +46721231231.'),
    tokenAttribute: 'mobileNumber',
    validation: {
      pattern: {
        value: mobileNumberRegex,
        message: t('Invalid input'),
      },
      required: t('Mobile number is required'),
    },
    trim: (s) => s.replace(/(\(0\))|[)(.\- ]/g, ''),
    props: {
      required: true,
    },
  }),
  'urn:oid:0.9.2342.19200300.100.1.3': (t) => ({
    type: 'urn:oid:0.9.2342.19200300.100.1.3',
    id: 'Email',
    label: t('Email'),
    helperText: '',
    tokenAttribute: 'email',
    validation: {
      pattern: {
        value: emailRegex,
        message: t('Submit a valid email address'),
      },
      required: t('Email is required'),
    },
    trim: R.pipe(removeAllWhitespace, R.toLower),
    props: {
      type: 'email',
      required: true,
    },
  }),
  'urn:oid:1.2.840.113556.1.4.656;urn:oid:0.9.2342.19200300.100.1.3': (t) => ({
    // Specially constructed attribute. First part says it is a UPN (= some kind of username),
    // the second part says it is an email address.
    type: 'urn:oid:1.2.840.113556.1.4.656;urn:oid:0.9.2342.19200300.100.1.3',
    id: 'upnUsername',
    label: t('Username'),
    helperText: t('upnUsername_helperText'),
    tokenAttribute: 'upn',
    validation: {
      pattern: {
        value: emailRegex,
        message: t('Submit a valid username'),
      },
      required: t('Username is required'),
    },
    trim: R.pipe(removeAllWhitespace, R.toLower),
    props: {
      type: 'email',
      required: true,
    },
  }),
  'urn:oid:2.5.4.12;urn:oid:2.16.840.1.113730.3.1.3': (t) => ({
    // Specially constructed attribute. First part says it is a Title (= EFOS ID),
    // the second part says it is an Employee number.
    type: 'urn:oid:2.5.4.12;urn:oid:2.16.840.1.113730.3.1.3',
    id: 'efosidEmployeeNumber',
    label: t('efosidEmployeeNumber'),
    helperText: t('efosidEmployeeNumber_helperText'),
    tokenAttribute: 'efos-id',
    validation: {
      pattern: {
        value: employeeNumberRegex,
        message: t('Submit a valid employee number'),
      },
      required: t('Employee number is required'),
    },
    trim: R.pipe(removeAllWhitespace, R.toLower),
    props: {
      required: true,
    },
  }),
  'urn:oid:1.2.752.201.3.1': (t) => ({
    type: 'urn:oid:1.2.752.201.3.1',
    id: 'orgAffiliation',
    label: t('orgAffiliation'),
    helperText: t('orgAffiliation_helperText'),
    tokenAttribute: 'org-affiliation',
    validation: {
      pattern: {
        value: orgAffiliationRegex,
        message: t('Submit a valid Organization Identity'),
      },
      required: t('Organization Identity is required'),
    },
    trim: R.pipe(removeAllWhitespace, R.toLower),
    props: {
      required: true,
    },
  }),
  'urn:oid:1.2.752.201.3.1;domstolsverket-service-id': (t) => ({
    // Specially constructed attribute. First part says it is an Organization Identity, the
    // second part says it is an "Tjänste-ID"/"Service-ID"
    type: 'urn:oid:1.2.752.201.3.1;domstolsverket-service-id',
    id: 'orgAffiliationServiceId',
    label: t('orgAffiliationServiceId'),
    helperText: t('orgAffiliationServiceId_helperText'),
    tokenAttribute: 'org-affiliation',
    validation: {
      pattern: {
        value: orgAffiliationRegex,
        message: t('Submit a valid Service-ID'),
      },
      required: t('Service-ID is required'),
    },
    trim: R.pipe(removeAllWhitespace, R.toLower),
    props: {
      required: true,
    },
  }),
  'urn:oid:1.2.752.29.6.2.1': (t) => ({
    type: 'urn:oid:1.2.752.29.6.2.1',
    id: 'hsa-id',
    label: t('HSA-ID'),
    helperText: t('hsaid_helperText'),
    tokenAttribute: 'hsa-id',
    validation: {
      pattern: {
        value: hsaIdRegex,
        message: t('Submit a valid HSA-ID'),
      },
      required: t('HSA-ID is required'),
    },
    trim: removeAllWhitespace,
    props: {
      required: true,
    },
  }),
};

export const getUrnoidToPropFunc = (key) => urnoidToPropFunc[key] ?? (() => ({}));

export const isPDFFile = (file) => {
  // The first 4 bytes if a pdf is required to have the same signature
  const pfdSignature = '25504446';
  return new Promise((resolve) => {
    const reader = new FileReader();
    // eslint-disable-next-line fp/no-mutation
    reader.onloadend = (evt) => {
      if (evt.target.readyState === FileReader.DONE) {
        const buffer = evt.target.result;
        const uint8Array = new Uint8Array(buffer);
        let header = '';
        uint8Array.forEach((byte) => {
          header += byte.toString(16);
        });
        if (header === pfdSignature) {
          resolve(true);
        }
        resolve(false);
      }
    };
    // eslint-disable-next-line fp/no-mutation
    reader.onerror = () => {
      // Resolve = false indicates that the file is corrupt or unreadable
      // therefore no need to Reject the promise
      // i.e. If the file does not pass this function, we should not accept it anyways
      resolve(false);
    };
    reader.readAsArrayBuffer(file.slice(0, 4)); // Read the first 4 bytes of the file
  });
};

const readFileSignature = async (file) => {
  const buffer = await file.slice(0, 4).arrayBuffer();
  const bytes = new Uint8Array(buffer);

  return Array.from(bytes)
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('')
    .toUpperCase();
};

// check if file is MS office file
export const isOfficeFile = async (file) => {
  // Read the first few bytes of the file
  const fileSignature = await readFileSignature(file);

  // Define known signatures for Office files
  const officeSignatures = [
    '504B0304', // ZIP file signature for modern formats (docx, xlsx, pptx)
    'D0CF11E0', // Binary signature for older Word files (doc, xls, ppt)
  ];

  // Check if the file matches known Office file signatures
  if (!officeSignatures.some((signature) => fileSignature.startsWith(signature))) return false;

  if (fileSignature.startsWith('504B0304')) {
    // Zip file, we need to check the file contents

    const zip = new JSZip();

    const loadedZip = await zip.loadAsync(file);

    // Check for [Content_Types].xml
    const hasContentTypes = loadedZip.file('[Content_Types].xml') !== null;

    // Check for specific folder structures
    const hasOfficeFolders =
      loadedZip.folder('word') || loadedZip.folder('xl') || loadedZip.folder('ppt');

    if (hasContentTypes && hasOfficeFolders) {
      return true;
    }
    return false;
  }
  return true;
};

export const isFilenameAllowed = (file) => !file.name.includes(';');
