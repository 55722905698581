export const en = {
  translations: {
    Welcome: 'Welcome',
    redirectMessage1: 'The SignPort service has moved to: ',
    redirectMessage2: 'Do not create new cases in this service, since it will be discontinued on ',
    Document: 'Document',
    Documents: 'Documents',
    Cases: 'Sign requests',
    'Sign request': 'Sign request',
    'Sign requests': 'Sign requests',
    'Create request': 'Create request',
    'Create sign request': 'Create sign request',
    'Light/Dark theme': 'Light/Dark theme',
    'Dark theme': 'Dark theme',
    'Log out': 'Log out',
    Language: 'Language',
    Created: 'Created',
    Administrator: 'Administrator',
    Continue: 'Continue',
    Prepare: 'Prepare',
    Signer: 'Signer',
    Signers: 'Signers',
    Name: 'Name',
    Message: 'Message',
    'Name (firstname lastname)': 'Name (Firstname Lastname)',
    'Name (firstname lastname) (optional)': 'Name (firstname lastname) (optional)',
    'Name is required': 'Name is required',
    'Organization is required': 'Organization is required',
    'Position is required': 'Position is required',
    Status: 'Status',
    'Signers (email)': 'Signers (email)',
    'Not accepted':
      'One or more files are not accepted. Files must be a PDF, the filename should not contain special characters and the file may not exceed 100MB: {{str}}',
    'Not accepted office or pdf files':
      'One or more files are not accepted. Files must be a PDF or MS Office files, the filename should not contain special characters and the file may not exceed 100MB: {{str}}',
    Options: 'Options',
    Revoke: 'Revoke',
    Reject: 'Reject',
    'Revoke case': 'Revoke case',
    'Reject case': 'Reject case',
    'Are you sure you want to revoke this case?': 'Are you sure you want to revoke this case?',
    'Are you sure you want to delete this case?': 'Are you sure you want to delete this case?',
    'Are you sure you want to reject this case?': 'Are you sure you want to reject this case?',
    'You may state a reason in the text box below, which is sent to all parties in the sign request':
      'You may state a reason in the text box below, which is sent to all parties in the sign request',
    'You must state a reason in the text box below, which is sent to all parties in the sign request':
      'You must state a reason in the text box below, which is sent to all parties in the sign request',
    Revoked: 'Revoked',
    Rejected: 'Rejected',
    Sent: 'Sent',
    Read: 'Read',
    Expired: 'Expired',
    Expiring: 'Expiring',
    Signed: 'Signed',
    'Partially signed': 'Partially signed',
    Search: 'Search',
    Delete: 'Delete',
    Edit: 'Edit',
    Confirm: 'Confirm',
    Characters: 'characters',
    'Change administrator': 'Change administrator',
    'Change administrator to': 'Change administrator to',
    'Change sign request': 'Change sign request',
    'The maximum length of a message is 2048 characters':
      'The maximum length of a message is 2048 characters',
    'Delete case': 'Delete case',
    'Change Authorization as administrator to': 'Change Authorization as administrator to',
    Cancel: 'Cancel',
    Close: 'Close',
    labelDisplayedRows_MaterialTable: '{from}-{to} of {count}',
    nRowsSelected_MaterialTable: '{0} row(s) selected',
    rows: 'rows',
    Login: 'Log in',
    CaseManager: 'Case manager',
    'Mobilt BankID': 'Mobile BankID',
    'Net iD Access': 'Net iD Access',
    SMS: 'SMS',
    'Mobile number': 'Mobile number (Ex: +46785555555)',
    'Mobile number is required': 'Mobile number is required',
    Actions: 'Actions',
    'No records to display': 'No records to display',
    Filter: 'Filter',
    Show: 'Show',
    Download: 'Download',
    DownloadAll: 'Download all',
    Sign: 'Sign',
    'Sign in order': 'Must be signed in order',
    'Start signing': 'Start signing',
    'Enter your signing details': 'Enter your signing details',
    Organization: 'Organization',
    'Organization (optional)': 'Organization (optional)',
    Position: 'Position',
    'Position (optional)': 'Position (optional)',
    Messages: 'Messages',
    'chat message to all parties in the signing assignment':
      'chat message to all parties in the signing assignment',
    'Also send email to': 'Also send email to',
    all: 'all',
    'not yet signed': 'not yet signed',
    administrator: 'administrator',
    none: 'none',
    'Write message': 'Write message',
    'No message to show': 'No message to show ',
    'Message history': 'Message history',
    'Awaiting your signature': 'Awaiting your signature',
    'You are currently not allowed to sign.': 'You are currently not allowed to sign.',
    'Others must sign before you': 'Others must sign before you',
    required: 'required',
    'required field text': 'Fields marked with an asterisk (*) are required',
    Send: 'Send',
    'Save and send': 'Save and send',
    // 'Write a message that you want to send': 'Write a message that you want to send',
    'Send message to all parties in the sign request':
      'Send message to all parties in the sign request',
    'Send reminder to all parties in the sign request who have not signed the sign request':
      'Send reminder to all parties in the sign request who have not signed the sign request',
    // 'Send reminder': 'Send reminder',
    // 'Revoke sign request': 'Revoke sign request',
    // 'Delete sign request': 'Delete sign request',
    // 'Sign all selected': 'Sign all the selected documents at once',
    'Start email verification process': 'Start email verification process',
    'Upload documents': 'Upload documents',
    'Drop document here or': 'Drop document here or',
    'Drop document here or_plural': 'Drop documents here or',
    // 'Drop documents here or': 'Drop documents here or',
    'select a file': 'select a file',
    'select a file_plural': 'select files',
    'Signer details': 'Signer {{index}}',
    Email: 'E-mail',
    Username: 'Username',
    orgAffiliation: 'Personal organizational identifier',
    orgAffiliationServiceId: 'Service-ID',
    efosidEmployeeNumber: 'Employee number',
    'HSA-ID': 'HSA-ID',
    orgAffiliation_helperText:
      'Format: identifier@organizational-id-number, where the identifier can be a username or an employment number, but other formats can occur. Example: abc123@0123456789',
    orgAffiliationServiceId_helperText:
      'Your Service-ID. Format: id@orgnr, e.g "12345@0123456789", where "12345" is an Service-ID number, and "0123456789" is a swedish organization number.',
    efosidEmployeeNumber_helperText: 'Your employee number.',
    hsaid_helperText:
      'Format: [county code][swedish organization number]-[serialnumber]. Example: SE2321000016-1003',
    upnUsername_helperText:
      'A username in the form of an (internal) email address. E.g josmi1@example.com, where "josmi1" is a username.',
    'Authentication method': 'Authentication method',
    'Require personal number': 'Require personal number',
    'At least one method required': 'At least one method required',
    'Choose authentication method': 'Choose authentication method',
    'Message to signers': 'Message to signers',
    'Message (optional)': 'Message to signers (optional)',
    'Expiration date': 'Expiration date',
    'Expiration date can not be later than':
      'The expiration date can not be later than {{maxDate}}',
    'Choose date (optional)': 'Choose date (optional)',
    'Add signer': 'Add signer',
    'Personal number': 'Personal number (12 characters)',
    'Swedish personal ID number. 12 digits without spaces and hyphens.':
      'Swedish personal ID number. 12 digits without spaces and hyphens.',
    'Personal number is required': 'Personal number is required',
    'You are not able to sign this request': 'You are not able to sign this request',
    'You do not have permission to view this sign request':
      'You do not have permission to view this sign request',
    'Sign request deleted': 'Sign request deleted',
    'The sign request you are looking for has been deleted':
      'The sign request you are looking for has been deleted',
    'Some sign request require validation by personal number':
      'Is the email-address correct? Some sign request require validation by personal number. Contact the administrator for more information',
    'You might not have permission for this': 'You might not have permission for this',
    'Invalid input': 'Invalid input',
    'Invalid emails': 'Submit a valid email address: {{emails}}',
    'Email is required': 'Email is required',
    'Email after signature': 'Email after signature',
    'After-signature-email-addresses':
      'e-mail addresses where the documents are sent after signature (optional)',
    'Username is required': 'Username is required',
    'Service-ID is required': 'Service-ID is required',
    'Organization Identity is required': 'Organization Identity is required',
    'HSA-ID is required': 'HSA-ID is required',
    'Add at least one file': 'Add at least one file',
    QuickSign: 'QuickSign',
    'Sign with': 'Sign with {{method}}',
    'Back to sign requests': 'Back to sign requests',
    Author: 'Author',
    'Email addresses connected to your account': 'Email addresses connected to your account',
    'Contact the administrator of your organization':
      'Contact the administrator of your organization',
    'Continue to the service': 'Continue to the service',
    'Verify email address': 'Verify email address',
    'You can choose to register an additional email address to your account':
      'You can choose to register an additional email address to your account. This is done under "Settings" - "Manage email addresses".',
    'To connect additional email addresses you have to log out and log in as a signer, and then start an email verification process':
      'To connect additional email addresses, you have to log out and log in as a signer and then start an email verification process',
    'Please make sure you have entered the correct url and have connected the correct email adress to your account':
      'Please make sure you have entered the correct url and have connected the correct email adress to your account',
    'You can connect additional adresses in the': 'You can connect additional adresses in the ',
    'settings page': 'settings page',
    'The sign request was not found': 'The sign request was not found',
    'No authentication method is available for you to sign this sign request':
      'No authentication method is available for you to sign this sign request',
    'Please make sure you type the correct address':
      'Please make sure you type the correct email address',
    'it-environment-warning':
      'The documents are sent by e-mail and therefore leave the IT environment',
    Validate: 'Validate',
    and: 'and',
    'Validate a digitally signed document': 'Validate a digitally signed document',
    'It is also possible to validate directly in acrobat reader. In order to do that you need to download and install the public certificate in Acrobat Reader':
      'It is also possible to validate directly in acrobat reader. In order to do that you need to download and install the public certificate in Acrobat Reader',
    'Documents (PDF) that have been digitally signed by one or more persons with a DIGG approved digital signature service can be validated here':
      'Documents (PDF) that have been digitally signed by one or more persons with a DIGG approved digital signature service can be validated here',
    'Validate directly in Adobe Acrobat Reader': 'Validate directly in Adobe Acrobat Reader',
    'It is also possible to validate directly in Acrobat Reader. In order to validate "green", you must download and add SignPorts public certificate to the trusted certificates list. A "yellow" validation means that Acrobat Reader does not trust the certificate.':
      'It is also possible to validate directly in Acrobat Reader. In order to validate "green", you must download and add SignPort\'s public certificate to the trusted certificates list. A "yellow" validation means that Acrobat Reader does not trust the certificate. Validation in Adobe Acrobat Reader is not based on DIGG\'s requirements.',
    'How to install the SignPort-Certificate': 'How to install the SignPort-Certificate',
    'acrobat-reader-contract-trust-alt':
      "Image that shows details about a contact named 'SignPort Production Root CA' with the email address signport-support@knowit.se. The window prompts the user to add the certificate to the list of trusted identities by clicking the 'Set Contact Trust...' button.",
    'acrobat-reader-trust-settings-alt':
      "Image of the 'Trust' tab in a certificate trust settings window. It displays a message explaining that a certificate must be designated as a trust anchor or be part of a chain to a trust anchor for signature validation to succeed.",
    Donwload: 'Download',
    'Other validation options': 'Other validation options',
    'Double-click the certificate file to add it as “Trusted” in Acrobat Reader.':
      'Double-click the certificate file to add it as “Trusted” in Acrobat Reader.',
    'Press the ”Set Contact Trust…” button.': 'Press the ”Set Contact Trust…” button.',
    'Check the ”Use this certificate as a trusted root” box.':
      'Check the ”Use this certificate as a trusted root” box.',
    'A computer restart might be required for the changes to be taken into effect.':
      'A computer restart might be required for the changes to be taken into effect.',
    'Signport production root certificate': 'Knowit Secure Solutions AB public certificate',
    'Cybercom certificate': 'To be able to validate signatures made before Q3 2024, download',
    'Cybercom certificate name': "Cybercom's public certificate.",
    'Instruction on how to install the certificate':
      'Instruction on how to install the certificate',
    Approved: 'Approved',
    'Not approved': 'Not approved',
    'Approved, with remarks': 'Approved, with remarks',
    Warning: 'Warning',
    'The document has not been changed since it has been signed. The persons listed in the digital signature are the persons that have signed the document.':
      'The document has not been changed since it has been signed. The persons listed in the digital signature are the persons that have signed the document.',
    "The document has not been changed, but the signatures can not be correctly validated. The document doesn't live up to DIGG's requirements for a digitally signed document.":
      "The document has not been changed, but the signatures can not be correctly validated. The document doesn't live up to DIGG's requirements for a digitally signed document.",
    'The document might have been changed since it has been signed and/or the signatures are not valid.':
      'The document might have been changed since it has been signed and/or the signatures are not valid.',
    'Something went wrong. Check if the file you upploaded is of the correct type (.pdf och .xml)':
      'Something went wrong. Check if the file you upploaded is of the correct type (.pdf och .xml)',
    'Something went wrong': 'Something went wrong',
    'SignPort for Knowit is temporary unavailable for scheduled maintenance':
      "SignPort for Knowit is temporary unavailable for scheduled maintenance It's expected to be operational again {{maintenanceDate}} 16:00",
    'Show report': 'Show report',
    'New validation': 'New validation',
    'Detailed report of the validation': 'Detailed report of the validation',
    'Below follows a detailed report on all the performed checks and the result of this examination':
      'Below follows a detailed report on all the performed checks and the result of this examination',
    'Detailed report': 'Detailed report',
    'Signature occasion': 'Signature occasion {{occasions}}',
    'Validation status for the signature': 'Validation status for the signature',
    'Validation status for signing certificate': 'Validation status for signing certificate',
    'Validation status for certificate chain, certificate':
      'Validation status for certificate chain, certificate',
    'The signature belongs to': 'The signature belongs to {{signer}}',
    "The digital signature service's certificate chain is":
      "The digital signature service's certificate chain is {{namesAuthorizationServices}}",
    'The moment of signing is': 'The moment of signing is {{moment}}',
    'We are moving to a new operating environment':
      'On {{maintenanceDate}} we are moving {{url}} to a new operating environment. At that time we will replace the public certificate for signatures, click',
    'Instructions adobe':
      "for instructions on how to add the new certificate to Adobe Acrobat in order to validate your document's signatures",
    'We are moving to a new operating environment no url':
      'On {{maintenanceDate}} we are moving {{url}} to a new operating environment.',
    'Validation page instructions':
      'On {{maintenanceDate}} we are moving {{url}} to a new operating environment. At that time, we will replace the public certificate for signatures. Below are instructions on how to add a certificate in Adobe Acrobat Reader to validate your document',
    here: 'here',
    missing: 'missing',
    'Timing of the validation report:': 'Timing of the validation report: {{timing}}',
    'Hide report': 'Hide report',
    Overview: 'Overview',
    'Summary: The document has been signed on':
      'Summary: The document has been signed on $t(occasion, {"count": {{number}}})',
    occasion: '{{count}} occasion',
    occasion_plural: '{{count}} occasions',
    'In total there have been': 'In total there have been $t(check, {"count": {{number}}})',
    check: '{{count}} check',
    check_plural: '{{count}} checks',
    'The number of approvals sentence':
      'The number of approvals is {{approvals}}, of which {{count}} has been approved with a remark',
    'The number of approvals sentence_plural':
      'The number of approvals is {{approvals}}, of which {{count}} have been approved with a remark',
    'All approvals (with or without a remark) have been highlighted green. Any checks that have not been approved have gotten a warning color, either orange (status Warning) or red (status Not Approved).':
      'All approvals (with or without a remark) have been highlighted green. Any checks that have not been approved have gotten a warning color, either orange (status Warning) or red (status Not Approved).',
    'something went wrong': 'Something went wrong',
    'error message': 'Error Message: ',
    'error code': 'Error Code: ',
    'an error occurred': 'An error occurred',
    'are you still there': 'Are you still there?',
    'You are not active': 'You are not active and will soon be logged out for security reasons',
    'time remaining': 'Time remaining: ',
    'Im here': 'I am here',
    'SMS is a weaker authentication method': 'SMS is a weaker authentication method',
    'Document successfully signed': 'Document successfully signed!',
    signpage: 'Signature page',
    language: 'Language',
    'du saknar tyvärr behörighet att använda den här tjänsten som handläggare. kontakta din administratör för att begära behörigheten.':
      'Unfortunately, you do not have permission to use this service as an Administrator. Contact your administrator to request authorization.',
    'Move Up': 'Move Up',
    'Move down': 'Move Down',
    'No signing page': 'No signing page',
    'Choose AuthenticationMethod': 'Choose authentication method',
    'Page Settings': 'Page settings',
    'Delegate authority as administrator': 'Delegate authority as administrator',
    'This connection already exists': 'This connection already exists',
    'Connected Email': 'Connected Email',
    'Delete Connection': 'Delete Connection',
    'Remove connection': 'Remove connection',
    'The following address will no longer be allowed to administer you documents':
      'The person with this email-address will no longer have your permission as administrator',
    'Authority as administrator delegated to me': 'Authority as administrator delegated to me',
    'My authority as administrator delegated to': 'My authority as administrator delegated to',
    'Invalid email': 'Invalid email',
    'Same user error': 'Not possible to add your own email address',
    'invalid personal number': 'Invalid personal number',
    'Email already connected': 'Email already connected',
    'This field is required': 'This field is required',
    'Create delegate authority connection': 'Create delegate authority connection',
    'The following address will be allowed to administer your documents':
      'The following address will be allowed to administer your documents',
    'Make Connection': 'Make Connection',
    'Delegate my authority as administrator': 'Delegate my authority as administrator',
    Submit: 'Submit',
    Settings: 'Settings',
    'Select which cases to view': 'Select which cases to view',
    'My cases': 'My cases',
    'Delegated cases': 'Delegated cases',
    'The expiration date must be from today': 'The expiration date must be from today',
    'All cases': 'All cases',
    'How to delegate your authority as administrator':
      'How to delegate your authority as administrator',
    'This feature allows you to share your created documents with other users':
      'This feature allows you to share your created documents with other users',
    'Allow other users to administer your documents':
      'Allow other users to administer your documents',
    'Delegate my authority as administrator to': 'Delegate my authority as administrator to',
    'Delegated authority valid until date': 'Delegated authority valid until date',
    Delegate: 'Delegate',
    'Remove delegation': 'Remove delegation',
    Add: 'Add',
    'State the reason for your rejection': 'State the reason for your rejection',
    'state the reason for your revocation': 'State the reason for your revocation',
    delegateHelpTitle: 'Here you can delegate your authority as administrator.',
    delegateHelp:
      'This function gives you the opportunity to let other administrators act as administrator on the sign requests that you have created. You add them by entering their email address. Those you have added are listed under "My authority as administrator delegated to". In the same way, other administrators can delegate their authority to you, these are listed under "Authority as an administrator delegated to me". You can remove delegation with the delete button in the respective list.',
    clearFilters: 'Clear all filters',
    from_date: 'From date',
    to_date: 'To date',
    createdByMe: 'Requests created by me',
    notCreatedByMe: 'Requests created by others',
    signerIsMe: 'Requests where i am a signer',
    signerIsNotMe: 'Requests where i am not a signer',
    Ok: 'Ok',
    'Please make sure you type the correct personal number':
      'Please make sure you type the correct personal number',
    'First Page': 'First Page',
    'Previous Page': 'Previous Page',
    'Next Page': 'Next Page',
    'Last Page': 'Last Page',
    'Zoom out': 'Zoom out',
    'Zoom in': 'Zoom in',
    'Side menu': 'Side menu',
    Languages: 'Languages',
    'available languages': 'available languages',
    Profile: 'Profile',
    'Submit a valid email address': 'Submit a valid email address',
    'Submit a valid username': 'Submit a valid username',
    'Submit a valid Organization Identity': 'Submit a valid organization identity',
    'Submit a valid employee number': 'Submit a valid employee number',
    'Submit a valid personal number': 'Submit a valid personal number',
    'Submit a valid eIDAS id': 'Submit a valid ID',
    'Submit a valid Service-ID': 'Submit a valid Service-ID',
    'Submit a valid HSA-ID': 'Submit a valid HSA-ID',
    'Authentication method required': 'Authentication method required',
    'Remove Email': 'Remove email address',
    'Add Email': 'Add email address',
    'Manage my emails': 'Manage emails',
    manageEmailsHelpTitle: 'Manage emails',
    manageEmailsHelp: 'Here you can add and remove emails that is connected to your account.',
    'The following email address will no longer be associated with this account':
      'The following email address will no longer be associated with this account',
    'Connected Emails': 'Connected Emails',
    'Don`t show this message again': 'Don`t show this message again',
    'Connect a new email address to my account': 'Connect a new email address to my account',
    'If you remove your last email address, you will be logged out of the app':
      'If you remove your last email address, you will be logged out of the app',
    'The provided email address does not have the same domain as your email address':
      'The provided email address does not have the same domain as your email address',
    'A user with an email address outside your organization might not be able to use the service as an administrator':
      'A user with an email address outside your organization might not be able to use the service as an administrator',
    'signpage required': 'A signature page is required',
    'same email twice': 'Same email may not exists more than once',
    'Back to login page': 'Back to login page',
    'Validate a document': 'Validate a document',
    'The file ': 'The file ',
    ' is not a valid PDF file. Please make sure the file is not damaged or corrupted and try again.':
      ' is not a valid PDF file. Please make sure the file is not damaged or corrupted and try again.',
    'Not valid MS Office or PDF':
      ' is not a valid MS Office or PDF file. Please make sure the file is not damaged or corrupted and try again.',
    'MS Office warning':
      'Non-PDF files will be converted to PDF. As the administrator, you need to review the sign request documents after creation.',
  },
};
