import React, { useCallback, useContext, useState, useEffect, useMemo, useRef } from 'react';

import * as R from 'ramda';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns/fp';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  CircularProgress,
  Typography,
  Button,
  TextField,
  Divider,
  InputLabel,
  Card,
  CardContent,
  Box,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { AppMachineContext } from '@store/appMachine';
import { Signer } from '@views/cases/components/Signer';
import { getFileName } from '@utils/data-utils';
import { emailRegex } from '@utils/regex';
import { getIdentityProviders } from '@store/authAPI';
import { changeCase, fetchCases, getAuthorConnectedCases } from '@store/appAPI';
import { DropDownSelect } from '@components/DropDownSelect';
import { FileSelector } from '@components/FileSelector';
import { DomainWarningMessage } from '@utils/userDomain';
import {
  formatMailOptions,
  processSignRequestData,
  hasRepeatedEmail,
  checkSignerHasIdp,
} from '@utils/signRequestFormFunctions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
  },
  box: { maxWidth: 640, marginBottom: theme.spacing(3) },
  subtitle: {
    padding: theme.spacing(2, 0, 1, 0),
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  select: {
    marginTop: '1.5em',
  },
  personalIdCheckbox: {
    marginBottom: '0',
  },
  signaturePage: {
    marginTop: '1rem',
  },
}));

const IDLE = Symbol('idle state');
const LOADING = Symbol('loading state');
const ERROR = Symbol('error state');

const isCheckboxDisabledForSigner = (signer) => signer?.signed;

export const ChangeCasePage = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation(['translations', 'errors']);
  const adminRef = useRef();
  const [signCase, setSignCase] = useState();
  const { templates } = useTheme();

  const [
    {
      context: { signIdps },
      matches,
    },
    send,
  ] = useContext(AppMachineContext);

  const getInitialState = () => ({
    signers: [
      ...(signCase?.Signers?.map((signer, idx) => ({
        id: `existingSigner${idx}`,
        signerId: signer.ID,
        email: signer.Email[0],
        name: signer.Name,
        personalID: signer.PersonalID,
        personalNumberCheckbox: !!signer.PersonalID, // Must be true or false, can not be undefined
        idps: signIdps.map((idp) => ({
          ...idp,
          deselected: !signer?.IdentityProviders?.some((signerIdp) => idp.id === signerIdp.id),
        })),
        // State for idp checkbox(true or false)
        idpsForSigner: signIdps.reduce(
          (idpMap, idp) => ({
            ...idpMap,
            [idp.id]: !!signer?.IdentityProviders?.some((signerIdp) => idp.id === signerIdp.id),
          }),
          {},
        ),
        signed: signer.Signed,
        signerHasSigned: signer.Signed,
      })) ?? []),
    ],
    lang: signCase?.Language,
  });
  const authorEmail = signCase?.Author?.Email;

  const classes = useStyles();
  const history = useHistory();

  const initialized = matches('authenticated.idle') && !R.isEmpty(signIdps);
  const [formState, setFormState] = useState({ state: IDLE }); // Perhaps better to go through machine?

  const {
    control,
    register,
    reset,
    handleSubmit,
    setValue,
    setError,
    errors,
    clearErrors,
    getValues,
    watch,
  } = useForm({ defaultValues: useMemo(getInitialState, [signCase]) });
  const { fields: signers, append: addSigner, move, remove } = useFieldArray({
    control,
    name: 'signers',
  });

  const [files, setFiles] = useState([]);
  const [expirationDate, setExpirationDate] = useState();
  const [changeAdministratorsEmail, setChangeAdministratorsEmail] = useState('');
  const [changeAdministratorsEmailError, setChangeAdministratorsEmailError] = useState('');
  const [mailTemplates] = useState(formatMailOptions(templates, i18n.language));
  const [repeatedEmailErrors, setRepeatedEmailErrors] = useState({});
  const [maxDate, setMaxDate] = useState(new Date());

  const [dateErrorMessage, setDateErrorMessage] = useState('');

  // to make useEffect run when we change something in signers
  const [signersUpdated, setSignersUpdated] = useState(false);
  const updateSignersToggle = () => setSignersUpdated(!signersUpdated);

  const validateExpirationDate = (data) => {
    const expDate = new Date(data.date);
    if (expDate < new Date(new Date().toDateString())) {
      setDateErrorMessage(t('The expiration date must be from today'));
      return false;
    }
    if (expDate > maxDate) {
      setDateErrorMessage(
        t('Expiration date can not be later than', {
          maxDate: maxDate.toISOString().split('T')[0],
        }),
      );
      return false;
    }
    return true;
  };

  const language = watch('lang');
  const validateNewAdministratorEmail = (newAdministratorEmail) => {
    const isEmail = newAdministratorEmail.match(emailRegex);

    if (isEmail) {
      setChangeAdministratorsEmailError('');
      return true;
    }
    setChangeAdministratorsEmailError(
      newAdministratorEmail.length ? 'Invalid email' : 'This field is required',
    );
    return false;
  };

  const showDomainWarning = (email) => email && email.match(emailRegex);

  useEffect(async () => {
    void getIdentityProviders().then((data) => send('SET_IDPS', { data }));
    const allCases = await fetchCases();
    const delegatedCases = await getAuthorConnectedCases();
    const newSignCase = [...allCases, ...delegatedCases]?.find(
      (c) => c.ID.toString() === id.toString(),
    );
    // If no sign case or everyone has signed, shouldn't change this case
    // Instead go back to the cases page
    if (!newSignCase || newSignCase?.Signers?.every((signer) => signer.Signed)) {
      history.push('/');
    }
    setSignCase(newSignCase);
  }, [send]);

  useEffect(() => {
    setFiles(signCase?.DocumentURLs?.map((path) => ({ name: getFileName(path) })));
    setExpirationDate(signCase?.ExpirationDate);
    const createdAt = signCase?.CreatedAt;
    const d = createdAt ? new Date(createdAt) : new Date();
    d.setDate(d.getDate() + 60);
    setMaxDate(d);
  }, [signCase]);
  useEffect(() => {
    reset(getInitialState());
  }, [reset, signCase]);

  const signerHasIdp = useCallback(
    ({ name, signerState }) => checkSignerHasIdp({ t, signIdps, setError, name, signerState }),
    [t, signIdps, setError],
  );
  const processData = useCallback(
    (data) =>
      processSignRequestData({
        signIdps,
        originalSignersData: signCase?.Signers,
        data,
        setError,
        t,
      }),
    [t, signCase, signIdps, signerHasIdp],
  );

  useEffect(() => {
    hasRepeatedEmail(getValues().signers, setRepeatedEmailErrors, t);
  }, [signersUpdated]);

  const submit = async (data) => {
    setFormState({ state: LOADING });
    const dataToSend = await processData(data);
    if (!validateExpirationDate(data)) {
      setFormState({ state: ERROR });
      return;
    }
    if (hasRepeatedEmail(data.signers, setRepeatedEmailErrors, t)) {
      setFormState({ state: ERROR });
      window.scrollTo(0, 0);
      return;
    }
    if (!dataToSend) {
      setFormState({ state: ERROR });
      return;
    }
    if (dataToSend.signpage === 'No signing page') dataToSend.signpage = '';
    dataToSend.caseId = id;
    if (changeAdministratorsEmail) dataToSend.newAdmin = changeAdministratorsEmail;

    changeCase(dataToSend)
      .then(() => history.push('/'))
      .catch((err) => {
        setFormState({ state: ERROR, message: err.message });
      });
  };

  const onSubmit = (...args) => {
    clearErrors();
    handleSubmit(submit)(...args);
  };

  const disableArrows = (signerIndex) => {
    const up = signerIndex === 0 || signers[signerIndex - 1].signed || signers[signerIndex].signed;
    const down =
      signerIndex === signers.length - 1 ||
      signers[signerIndex + 1].signed ||
      signers[signerIndex].signed;
    return {
      up,
      down,
    };
  };

  if (!signCase)
    return (
      <Box p={2}>
        <CircularProgress />
      </Box>
    );

  return (
    <form className={classes.root} onSubmit={onSubmit} noValidate>
      <Card className={classes.box}>
        <CardContent>
          <FileSelector
            isChangeCaseComponent
            className={classes.box}
            register={register}
            files={files}
            setValue={setValue}
            error={errors.files}
          />
        </CardContent>
      </Card>

      {signers.map((signer, index) => (
        <>
          <Signer
            name={`signers[${index}]`}
            existingEmail={signer?.email}
            existingSignerName={signer?.name}
            existingSignerPersonalID={signer?.personalID}
            disabledCheckbox={isCheckboxDisabledForSigner(signer)}
            errors={errors?.signers?.[index]}
            key={`signer${signer.id}`}
            index={index}
            classes={classes}
            totalCount={signers.length}
            setValue={(n, val) => {
              updateSignersToggle();
              setValue(n, val);
            }}
            getValues={getValues}
            move={(indexA, indexB) => {
              updateSignersToggle();
              move(indexA, indexB);
            }}
            remove={(idx) => {
              updateSignersToggle();
              remove(idx);
            }}
            register={register}
            idps={signer?.idps ?? signIdps}
            signed={signer?.signed}
            repeatedEmailErrors={repeatedEmailErrors[index]}
            disableArrows={disableArrows(index)}
            personalNumberCheckbox={signer?.personalNumberCheckbox}
            control={control}
            idpsForSigner={signer?.idpsForSigner}
          />
          <input
            name={`signers[${index}].id`}
            id="signerId"
            type="hidden"
            value={signer?.signerId ?? ''}
            ref={register()}
          />
        </>
      ))}
      <div className={classes.box}>
        <Button startIcon={<AddIcon />} variant="contained" color="secondary" onClick={addSigner}>
          {t('Add signer')}
        </Button>
      </div>
      <Card className={classes.box}>
        <CardContent>
          <div className={classes.box}>
            <Typography variant="subtitle1" className={classes.uppercase}>
              {t('Message to signers')}
            </Typography>
            <Divider />
            <DropDownSelect
              items={mailTemplates}
              register={register}
              refName="lang"
              label={t('language')}
              setValue={setValue}
              defaultValue={language}
            />
            <InputLabel htmlFor="message" className={classes.subtitle}>
              {t('Message (optional)')}
            </InputLabel>
            <TextField
              id="message"
              name="message"
              inputRef={register}
              fullWidth
              multiline
              rows="8"
              inputProps={{
                maxLength: 2048,
              }}
            />
          </div>
          <div className={classes.box}>
            <Typography variant="subtitle1" className={classes.uppercase}>
              {t('Expiration date')}
            </Typography>
            <Divider />
            <InputLabel htmlFor="date" className={classes.subtitle}>
              {t('Choose date (optional)')}
            </InputLabel>
            <TextField
              id="date"
              name="date"
              type="date"
              inputRef={register}
              error={dateErrorMessage}
              value={
                expirationDate && new Date(expirationDate).getYear() > 0
                  ? format('yyyy-MM-dd', new Date(expirationDate))
                  : undefined
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: format('yyyy-MM-dd', new Date()),
                max: format('yyyy-MM-dd', maxDate),
              }}
              onChange={(e) => setExpirationDate(e.target.value)}
            />
            {dateErrorMessage && <Typography color="error">{dateErrorMessage}</Typography>}
          </div>
        </CardContent>
      </Card>
      <Card className={classes?.box}>
        <CardContent>
          <Typography variant="subtitle1" className={classes?.uppercase}>
            {t('Administrator')}
          </Typography>
          <Divider />
          <InputLabel htmlFor="email" className={classes?.subtitle} required>
            {t('Email')}
          </InputLabel>
          <TextField
            multiline
            rows={1}
            rowsMax={1}
            margin="dense"
            id="email"
            fullWidth
            inputRef={adminRef}
            defaultValue={authorEmail}
            autoFocus
            required
            inputProps={{
              maxLength: 254, // RFC 5321 standard
              'aria-required': 'true',
            }}
            onChange={(e) => {
              const newEmail = String(e.target.value).toLowerCase();
              validateNewAdministratorEmail(newEmail);
              setChangeAdministratorsEmail(newEmail);
            }}
          />
          {changeAdministratorsEmailError && (
            <Typography color="error">{t(changeAdministratorsEmailError)}</Typography>
          )}
          {showDomainWarning(changeAdministratorsEmail) && (
            <DomainWarningMessage email={changeAdministratorsEmail} />
          )}
        </CardContent>
      </Card>
      <div className={classes.box}>
        {formState.state === ERROR && formState.message && (
          <p>
            <Typography color="error">{t(formState.message, { ns: 'errors' })}</Typography>
          </p>
        )}
        {formState.state === LOADING || !initialized ? (
          <CircularProgress />
        ) : (
          <Button type="submit" variant="contained" color="primary">
            {t('Save and send')}
          </Button>
        )}
      </div>
    </form>
  );
};
