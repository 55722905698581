import { Base64 } from 'js-base64';
// import * as R from 'ramda';

import { sessionRead } from '../utils/storage';
import { prepareCases } from '../utils/caseTransforms';
import {
  appUrl,
  // mocked,
  mockedAsync,
  hiddenFormSubmitter,
  fetchCheckedJson,
  createFormData,
  sleep,
} from '../utils/url';
import { authorConnectionsMock, casesMock, caseMock, delegatedCasesMock } from './mocks';
import { refreshToken } from './authAPI';

const getHeaders = () => {
  const token = sessionRead('access_token');
  if (!token) return [Error('Missing token')];
  return [undefined, new Headers({ Authorization: `bearer ${token}` })];
};

/* ****************************************************
    URLs
*/
const casesUrl = () => appUrl('/api/documentservice/guard/documents');
const caseUrl = (caseId) => appUrl(`/api/documentservice/guard/documents/${caseId}`);

const signRequestUrl = (caseId) => appUrl(`/api/documentservice/guard/signrequest/${caseId}`);
const revokeCaseUrl = (caseId) => appUrl(`/api/documentservice/guard/documents/${caseId}/revoke`);
const rejectCaseUrl = (caseId) => appUrl(`/api/documentservice/guard/documents/${caseId}/reject`);
const readCaseUrl = (caseId) => appUrl(`/api/documentservice/guard/documents/${caseId}/read`);
const deleteCaseUrl = (caseId) => appUrl(`/api/documentservice/guard/documents/${caseId}`);
const sendReminderUrl = (caseId) =>
  appUrl(`/api/documentservice/guard/documents/${caseId}/sendreminder`);
const getMessageUrl = (caseId) => appUrl(`/api/documentservice/guard/documents/${caseId}/messages`);
const updateCaseUrl = (caseId) => appUrl(`/api/documentservice/guard/documents/${caseId}`);

const ConnectAuthorUrl = () => appUrl('/api/documentservice/guard/ConnectAuthor');
const GetAuthorConnectionsUrl = () => appUrl('/api/documentservice/guard/AuthorConnections');
const DeleteAuthorConnectionUrl = (connectionId) =>
  appUrl(`/api/documentservice/guard/AuthorConnection/${connectionId}`);
const AuthorConnectedCasesUrl = () => appUrl('/api/documentservice/guard/ConnectedDocuments');

const RemoveUserEmailUrl = () => appUrl('/api/oauthservice/guard/removeEmail');

/* ****************************************************
    App requests
*/

export const HttpStatus = {
  NO_CONTENT: 204,
  PERMISSION_DENIED: 401,
};

export const fetchCases = () => {
  const mock = casesMock;
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(casesUrl(), { headers, refreshTokenFunc: refreshToken });
  };
  return mockedAsync(mock, liveFn).then(prepareCases);
};

export const fetchCase = (caseId) => {
  const mock = caseMock;
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetch(caseUrl(caseId), { headers, refreshTokenFunc: refreshToken });
  };
  return mockedAsync(mock, liveFn);
};

export const createCase = ({
  signers = [],
  files,
  message = '',
  date = new Date('0001-01-01'),
  signInOrder,
  signpage = '',
  lang = '',
  sendEmail = true,
  completedSignRequestRecipients = [],
}) => {
  const data = {
    permissions: 'all',
    sendEmail,
    signers: JSON.stringify(signers),
    ...files.reduce((acc, file, idx) => ({ ...acc, [`document-${idx}`]: file }), {}),
    reminderMessageB64: Base64.encode(message),
    expirationDate: date.toISOString(),
    forceSigningOrder: Boolean(signInOrder),
    SignaturePageTemplateID: signpage,
    language: lang,
    completedSignRequestRecipients: JSON.stringify(completedSignRequestRecipients),
  };
  const mockFn = () => sleep(1000, { Document: { ID: 210 } });
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(casesUrl(), {
      method: 'POST',
      headers,
      body: createFormData(data),
      refreshTokenFunc: refreshToken,
    });
  };
  return mockedAsync(mockFn, liveFn);
};

export const changeCase = ({
  signers = [],
  message = '',
  date = new Date('0001-01-01'),
  signpage = '',
  lang = '',
  sendEmail = true,
  caseId,
  newAdmin = '',
}) => {
  const data = {
    permissions: 'all',
    sendEmail,
    signers: JSON.stringify(signers),
    reminderMessageB64: Base64.encode(message),
    expirationDate: date.toISOString(),
    SignaturePageTemplateID: signpage,
    language: lang,
    newAdmin,
  };
  const mockFn = () => sleep(1000, { Document: { ID: 210 } });
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(updateCaseUrl(caseId), {
      method: 'PUT',
      headers,
      body: createFormData(data),
      refreshTokenFunc: refreshToken,
    });
  };
  return mockedAsync(mockFn, liveFn);
};

export const sendSignRequest = ({ caseId, data }) => {
  const mockFn = () => {
    window.location = appUrl('/');
  };
  const liveFn = async () => {
    const [error, headers] = getHeaders();
    // console.log('sendSignRequest:', caseId, headers, data);
    if (error) return Promise.reject(error);
    const response = await fetchCheckedJson(signRequestUrl(caseId), {
      method: 'POST',
      headers,
      body: createFormData(data),
      refreshTokenFunc: refreshToken,
    });
    hiddenFormSubmitter(
      { action: response.SigningServiceUrl },
      {
        Binding: response.Binding,
        RelayState: response.RelayState,
        EidSignRequest: response.EidSignRequest,
      },
    );
    return response;
  };
  return mockedAsync(mockFn, liveFn);
};

export const revokeCase = (caseId, message) => {
  const data = { reminderMessageB64: Base64.encode(message) };
  const mock = { ID: caseId };
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(revokeCaseUrl(caseId), {
      method: 'PUT',
      headers,
      body: createFormData(data),
      refreshTokenFunc: refreshToken,
    });
  };
  return mockedAsync(mock, liveFn);
};

export const rejectCase = (caseId, message) => {
  const data = { reminderMessageB64: Base64.encode(message) };
  const mock = { ID: caseId };
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(rejectCaseUrl(caseId), {
      method: 'PUT',
      headers,
      body: createFormData(data),
      refreshTokenFunc: refreshToken,
    });
  };
  return mockedAsync(mock, liveFn);
};

export const readCase = (caseId) => {
  const mock = { ID: caseId };
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(readCaseUrl(caseId), {
      method: 'PUT',
      headers,
      refreshTokenFunc: refreshToken,
    });
  };
  return mockedAsync(mock, liveFn);
};

export const deleteCase = (caseId) => {
  const mock = { ID: caseId };
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(deleteCaseUrl(caseId), {
      method: 'DELETE',
      headers,
      refreshTokenFunc: refreshToken,
    });
  };
  return mockedAsync(mock, liveFn);
};

export const sendReminder = (caseId, message = '-', sendTo = 'all') => {
  const data = { reminderMessageB64: Base64.encode(message), sendTo };
  const mockFn = () => sleep(1000);
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(sendReminderUrl(caseId), {
      method: 'POST',
      headers,
      body: createFormData(data),
      refreshTokenFunc: refreshToken,
    });
  };
  return mockedAsync(mockFn, liveFn);
};

// get message history for a document

export const getMessageHistory = (caseId) => {
  const mockFn = () => sleep(1000);
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(getMessageUrl(caseId), {
      method: 'GET',
      headers,
      refreshTokenFunc: refreshToken,
    });
  };
  return mockedAsync(mockFn, liveFn);
};

// validate signing case
// eslint-disable-next-line arrow-body-style
export const validate = (file) => {
  return fetchCheckedJson(appUrl(`/api/documentservice/validate`), {
    method: 'POST',
    mode: 'cors',
    body: createFormData({ file }),
    refreshTokenFunc: refreshToken,
  });
};

export const addAuthorConnection = (emailToConnect, expirationDate) => {
  const data = {
    email: emailToConnect,
    expirationDate: expirationDate ? `${expirationDate}T23:59:59Z` : '',
  };
  const mock = { emailToConnect, expirationDate };
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(ConnectAuthorUrl(), {
      method: 'POST',
      headers,
      body: createFormData(data),
      refreshTokenFunc: refreshToken,
    });
  };
  return mockedAsync(mock, liveFn);
};

export const changeAdmin = (caseId, newAdmin) => {
  const data = {
    newAdmin,
  };
  const mock = { newAdmin };
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(updateCaseUrl(caseId), {
      method: 'PUT',
      headers,
      body: createFormData(data),
      refreshTokenFunc: refreshToken,
    });
  };
  return mockedAsync(mock, liveFn);
};

export const getAuthorConnections = () => {
  const mock = authorConnectionsMock;
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(GetAuthorConnectionsUrl(), {
      headers,
      refreshTokenFunc: refreshToken,
    });
  };
  return mockedAsync(mock, liveFn);
};

export const deleteAuthorConnection = (id) => {
  const mock = { id };
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(DeleteAuthorConnectionUrl(id), {
      method: 'DELETE',
      headers,
      refreshTokenFunc: refreshToken,
    });
  };
  return mockedAsync(mock, liveFn);
};

export const getAuthorConnectedCases = () => {
  const mock = delegatedCasesMock;
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(AuthorConnectedCasesUrl(), {
      headers,
      refreshTokenFunc: refreshToken,
    });
  };
  return mockedAsync(mock, liveFn).then(prepareCases);
};

export const removeUserEmail = (emailToRemove) => {
  const data = {
    email: emailToRemove,
  };
  const mock = { emailToRemove };
  const liveFn = () => {
    const [error, headers] = getHeaders();
    if (error) return Promise.reject(error);
    return fetchCheckedJson(RemoveUserEmailUrl(), {
      method: 'POST',
      headers,
      body: createFormData(data),
      refreshTokenFunc: refreshToken,
    });
  };
  return mockedAsync(mock, liveFn);
};
