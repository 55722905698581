/* eslint-disable max-lines */
import { Base64 } from 'js-base64';

export const identityProviders = [
  {
    entityId: 'https://eid.test.legitimeringstjanst.se/sc/mobilt-bankid/',
    displayName: 'Mobilt BankID',
    displayName_en: 'Mobile BankID',
    requiredFields: ['urn:oid:1.2.752.29.4.13'],
    enabledForSigning: true,
    containsPersonalNumber: true,
  },
  {
    entityId: 'https://eid.test.legitimeringstjanst.se/sc/bankid/',
    displayName: 'BankID',
    enabledForSigning: true,
    requiredFields: ['urn:oid:1.2.752.29.4.13'],
    containsPersonalNumber: true,
  },
  {
    entityId: 'https://eid.test.legitimeringstjanst.se/sc/netidaccess/',
    displayName: 'Net iD Access',
    requiredFields: ['urn:oid:1.2.752.29.4.13'],
    enabledForSigning: true,
    containsPersonalNumber: true,
  },
  {
    entityId: 'https://eid.test.signport.se/sms/',
    displayName: 'SMS',
    requiredFields: ['urn:oid:0.9.2342.19200300.100.1.41'],
    enabledForSigning: true,
  },
  {
    entityId: 'https://sts.windows.net/6735929c-9dbf-473b-9fc6-5fbdcd2c9fc4/',
    displayName: 'Medarbetare hos Knowit',
    displayName_en: 'Employee at Knowit',
    enabledForSigning: false,
  },
];

export const loginOptionsMock = { identityProviders };

export const loggedInUser = {
  id: 255,
  email: [
    'test2@test2.test',
    'test@test.test',
    'extremely.long.name+familyname@alsolongdomain.webpage',
  ],
  name: 'Test Testsson',
};
export const loginMock = {
  IdpURL: 'https://sts.windows.net/6735929c-9dbf-473b-9fc6-5fbdcd2c9fc4/',
  RelayState: 'RelayState',
  SAMLRequest: 'SAMLRequest',
};

const tokenMock =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlSURBU1BlcnNvbklkZW50aWZpZXIiOiIiLCJlSURBU1Byb3Zpc2lvbmFsSUQiOiIiLCJlbWFpbCI6ImVzYmpvcm4uYmxvbXF1aXN0QGN5YmVyY29tLmNvbSIsImV4cCI6MjYwMzM3OTAxNSwiaXMtb3JnYW5pemF0aW9uLXNlcnZpY2UtYWNjb3VudCI6ZmFsc2UsIm1vYmlsZU51bWJlciI6IiIsIm5hbWUiOiIiLCJvcmdhbml6YXRpb24taWRlbnRpZmllciI6ImN5YmVyY29tLnRlc3Quc2lnbnBvcnQuc2UiLCJwZXIiOlsiYWxsIl0sInBlcnNvbmFsSUQiOiIiLCJyb2xlLXNlbmRlciI6dHJ1ZSwic3ViIjoiV0dLaFRMaXhUR21pSnBpb1I2VUVPdyJ9._wlG1-zVIVRBWOt1OGaUduwZSGOhoT6EZ8cQ5BOGCxI';
export const getTokenMockResponse = (signal) =>
  signal === 'verify'
    ? {
        access_token: tokenMock,
        'email-verification-idp-entity-id': 'https://eid.test.signport.se/email/',
        'email-verification-optional': true,
      }
    : {
        access_token: tokenMock,
        'email-verification-idp-entity-id': 'https://eid.test.signport.se/email/',
        'email-verification-optional': true,
      };
// export const tokenMockEmail = { access_token: 'test', 'email-verification-idp-entity-id': true };

export const casesMock = [
  {
    ID: 121,
    CreatedAt: '2022-10-16T08:16:29.961594Z',
    UpdatedAt: '2022-10-16T08:16:29.961594Z',
    DeletedAt: null,
    Author: {
      ID: 13,
      CreatedAt: '2022-10-16T08:16:29.822642Z',
      UpdatedAt: '2022-10-16T08:41:42.507528Z',
      DeletedAt: null,
      FirstName: 'Verainho',
      LastName: 'Uttersson',
      Email: 'verainho.uttersson@knowit.se',
    },
    AuthorID: 13,
    DocumentURL:
      '/documentstorage/33.451910736Z-mockpdf-long.pdf;/documentstorage/33.451910736Z-loremipsum.pdf;',
    ExpirationDate: '0001-01-01T00:00:00Z',
    Permissions: 'all',
    ReminderMessageB64: Base64.encodeURI(`Test
    line2.

    line4.`),
    ReturnURL: '',
    Revoked: false,
    Signers: [
      {
        ID: 132,
        CreatedAt: '2022-10-16T08:16:30.053795Z',
        UpdatedAt: '2022-10-16T08:16:30.053795Z',
        DeletedAt: null,
        DocumentID: 121,
        Email: 'test@test.test',
        FirstName: 'Verainho',
        LastName: 'Uttersson',
        Signed: false,
        Read: false,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            enabledForSigning: true,
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
          },
          {
            entityId: 'https://eid.signport.se/sms/',
            displayName: 'SMS',
            enabledForSigning: true,
            requiredFields: ['urn:oid:0.9.2342.19200300.100.1.41'],
          },
        ],
      },
      {
        ID: 135,
        CreatedAt: '2022-10-16T08:16:30.053795Z',
        UpdatedAt: '2022-10-16T08:16:30.053795Z',
        DeletedAt: null,
        DocumentID: 121,
        Email: 'verainho.uttersson@knowit.se',
        Signed: false,
        Read: false,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            enabledForSigning: true,
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
          },
        ],
      },
      {
        ID: 132,
        CreatedAt: '2022-10-16T08:16:30.053795Z',
        UpdatedAt: '2022-10-16T08:16:30.053795Z',
        DeletedAt: null,
        DocumentID: 121,
        Email: 'test2@test2.test',
        Signed: true,
        Read: false,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/netidaccess/',
            displayName: 'Net iD Access (personnummer)',
            enabledForSigning: true,
            enabledForAuthentication: false,
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
          },
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/netidaccess-email/',
            displayName: 'Net iD Access (email)',
            enabledForSigning: true,
            enabledForAuthentication: false,
            requiredFields: ['urn:oid:0.9.2342.19200300.100.1.3'],
          },
        ],
      },
    ],
    SigningMessage: '',
    ForceSigningOrder: false,
    State: '',
    OrganizationIdentifier: 'knowit.signport-qa.se',
  },
  {
    ID: 122,
    CreatedAt: '2022-10-16T08:41:42.552036Z',
    UpdatedAt: '2022-10-16T08:41:42.552036Z',
    DeletedAt: null,
    Author: {
      ID: 13,
      CreatedAt: '2022-10-16T08:16:29.822642Z',
      UpdatedAt: '2022-10-16T08:41:42.507528Z',
      DeletedAt: null,
      FirstName: 'Verainho',
      LastName: 'Uttersson',
      Email: 'verainho.uttersson@knowit.se',
    },
    AuthorID: 13,
    DocumentURL:
      '/documentstorage/33.451910736Z-mockpdf-long.pdf;/documentstorage/33.451910736Z-mockpdf-long 37%.pdf;/documentstorage/33.451910736Z-loremipsum.pdf;/documentstorage/33.451910736Z-mockpdf.pdf;',
    ExpirationDate: '0001-01-01T00:00:00Z',
    Permissions: 'all',
    ReminderMessageB64: Base64.encodeURI('Test\n\nline3.'),
    ReturnURL: '',
    Revoked: false,
    Signers: [
      {
        ID: 133,
        CreatedAt: '2022-10-16T08:41:42.641868Z',
        UpdatedAt: '2022-10-16T08:41:42.641868Z',
        DeletedAt: null,
        DocumentID: 122,
        Email: 'test@test.test',
        Signed: false,
        Read: true,
        Rejected: false,
        IdentityProviders: null,
      },
    ],
    SigningMessage: '',
    ForceSigningOrder: false,
    State: '',
    OrganizationIdentifier: 'knowit.signport-qa.se',
  },
  {
    ID: 209,
    CreatedAt: '2022-06-02T09:13:06.707125Z',
    UpdatedAt: '2022-06-04T18:37:14.605599Z',
    DeletedAt: null,
    Author: {
      ID: 20,
      CreatedAt: '2022-06-02T09:13:06.570021Z',
      UpdatedAt: '2022-06-04T18:37:14.55818Z',
      DeletedAt: null,
      FirstName: 'Test',
      LastName: 'Testsson',
      Email: 'test2@test2.test',
    },
    AuthorID: 20,
    DocumentURL:
      '/documentstorage/33.451910736Z-mockpdf-long 37%.pdf;/documentstorage/33.451910736Z-loremipsum.pdf;',
    DocumentMimetype: 'application/pdf',
    ExpirationDate: '0001-01-01T00:00:00Z',
    Permissions: 'all',
    ReminderMessageB64: Base64.encodeURI('1\\n\\n2'),
    ReturnURL: '',
    Revoked: true,
    Signers: [
      {
        ID: 254,
        CreatedAt: '2022-06-02T09:13:06.795857Z',
        UpdatedAt: '2022-06-04T18:37:14.65296Z',
        DeletedAt: null,
        DocumentID: 209,
        FirstName: 'Test',
        LastName: 'Testsson',
        Email: 'test2@test2.test',
        MobileNumber: '',
        PersonalID: '178901235013',
        Organization: 'Knowit',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: false,
        Read: true,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
        ],
      },
      {
        ID: 259,
        CreatedAt: '2022-06-02T09:13:06.795857Z',
        UpdatedAt: '2022-06-04T18:37:14.65296Z',
        DeletedAt: null,
        DocumentID: 209,
        FirstName: 'Test',
        LastName: 'Testsson',
        Email: 'test@test.test',
        MobileNumber: '',
        PersonalID: '178901234768',
        Organization: 'Knowit',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: true,
        Read: true,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
        ],
      },
    ],
    SigningMessage: '',
    ForceSigningOrder: true,
    State: '',
    OrganizationIdentifier: 'knowit.signport.se',
  },
  {
    ID: 210,
    CreatedAt: '2022-06-02T19:50:33.806059Z',
    UpdatedAt: '2022-06-02T19:50:33.806059Z',
    DeletedAt: null,
    Author: {
      ID: 20,
      CreatedAt: '2022-06-02T09:13:06.570021Z',
      UpdatedAt: '2022-06-04T18:37:14.55818Z',
      DeletedAt: null,
      FirstName: 'Test',
      LastName: 'Testsson',
      Email: 'test2@test2.test',
    },
    AuthorID: 20,
    DocumentURL: '/documentstorage/33.451910736Z-mockpdf-long 37%.pdf;',
    DocumentMimetype: 'application/pdf',
    ExpirationDate: '0001-01-01T00:00:00Z',
    Permissions: 'all',
    ReminderMessageB64: Base64.encodeURI('sdf'),
    ReturnURL: '',
    Revoked: false,
    Signers: [
      {
        ID: 255,
        CreatedAt: '2022-06-02T19:50:33.900676Z',
        UpdatedAt: '2022-06-02T19:50:33.900676Z',
        DeletedAt: null,
        DocumentID: 210,
        FirstName: 'Verainho ',
        LastName: 'Uttersson',
        Email: 'test@test.test',
        MobileNumber: '0701740605',
        PersonalID: '178901235013',
        Organization: '',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: false,
        Read: true,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/netidaccess/',
            displayName: 'Net iD Access (personnummer)',
            enabledForSigning: true,
            enabledForAuthentication: false,
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
          },
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/netidaccess-email/',
            displayName: 'Net iD Access (email)',
            enabledForSigning: true,
            enabledForAuthentication: false,
            requiredFields: ['urn:oid:0.9.2342.19200300.100.1.3'],
          },
        ],
      },
    ],
    SigningMessage: '',
    ForceSigningOrder: false,
    State: '',
    OrganizationIdentifier: 'knowit.signport.se',
  },
  {
    ID: 211,
    CreatedAt: '2022-04-02T19:50:33.806059Z',
    UpdatedAt: '2022-04-02T19:50:33.806059Z',
    DeletedAt: null,
    Author: {
      ID: 20,
      CreatedAt: '2022-05-02T09:13:06.570021Z',
      UpdatedAt: '2022-05-04T18:37:14.55818Z',
      DeletedAt: null,
      FirstName: 'V',
      LastName: 'Sparvsson',
      Email: 'amelinhosparvsson@gmail.com',
    },
    AuthorID: 20,
    DocumentURL:
      '/documentstorage/33.451910736Z-loremipsum.pdf;/documentstorage/33.451910736Z-loremipsum (copy).pdf;',
    DocumentMimetype: 'application/pdf',
    ExpirationDate: '2022-05-01T00:00:00Z',
    Permissions: 'all',
    ReminderMessageB64: Base64.encodeURI('sdf'),
    ReturnURL: '',
    Revoked: false,
    Signers: [
      {
        ID: 255,
        CreatedAt: '2022-06-02T19:50:33.900676Z',
        UpdatedAt: '2022-06-02T19:50:33.900676Z',
        DeletedAt: null,
        DocumentID: 210,
        FirstName: 'Verainho ',
        LastName: 'Uttersson',
        Email: 'test@test.test',
        MobileNumber: '0701740605',
        PersonalID: '178901235013',
        Organization: '',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: false,
        Read: false,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
        ],
      },
    ],
    SigningMessage: '',
    ForceSigningOrder: false,
    State: '',
    OrganizationIdentifier: 'knowit.signport.se',
  },
  {
    ID: 218,
    CreatedAt: '2022-04-02T19:50:33.806059Z',
    UpdatedAt: '2022-04-02T19:50:33.806059Z',
    DeletedAt: null,
    Author: {
      ID: 20,
      CreatedAt: '2022-06-15T09:13:06.570021Z',
      UpdatedAt: '2022-06-15T18:37:14.55818Z',
      DeletedAt: null,
      FirstName: 'Test',
      LastName: 'Testsson',
      Email: 'test@test.test',
    },
    AuthorID: 20,
    DocumentURL: '/documentstorage/33.451910736Z-Testavtal (signers4).pdf;',
    DocumentMimetype: 'application/pdf',
    ExpirationDate: '0001-01-01T00:00:00Z',
    Permissions: 'all',
    ReminderMessageB64: Base64.encodeURI('sdf'),
    ReturnURL: '',
    Revoked: false,
    Signers: [
      {
        ID: 255,
        CreatedAt: '2022-06-02T19:50:33.900676Z',
        UpdatedAt: '2022-06-02T19:50:33.900676Z',
        DeletedAt: null,
        DocumentID: 210,
        FirstName: 'Verainho ',
        LastName: 'Uttersson',
        Email: 'test@test.test',
        MobileNumber: '0701740605',
        PersonalID: '178901235013',
        Organization: '',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: true,
        Read: false,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
        ],
      },
    ],
    SigningMessage: '',
    ForceSigningOrder: false,
    State: '',
    OrganizationIdentifier: 'knowit.signport.se',
  },
  {
    ID: 212,
    CreatedAt: '2022-06-20T19:50:33.806059Z',
    UpdatedAt: '2022-04-02T19:50:33.806059Z',
    DeletedAt: null,
    Author: {
      ID: 20,
      CreatedAt: '2022-06-15T09:13:06.570021Z',
      UpdatedAt: '2022-06-15T18:37:14.55818Z',
      DeletedAt: null,
      FirstName: 'E',
      LastName: 'Sparvsson',
      Email: 'amelinhosparvsson@gmail.com',
    },
    AuthorID: 20,
    DocumentURL: '/documentstorage/33.451910736Z-Testavtal test jätte långt filnamn (signed4).pdf;',
    DocumentMimetype: 'application/pdf',
    ExpirationDate: '0001-01-01T00:00:00Z',
    Permissions: 'all',
    ReminderMessageB64: Base64.encodeURI('sdf'),
    ReturnURL: '',
    Revoked: false,
    Signers: [
      {
        ID: 259,
        CreatedAt: '2022-06-02T09:13:06.795857Z',
        UpdatedAt: '2022-06-04T18:37:14.65296Z',
        DeletedAt: null,
        DocumentID: 209,
        FirstName: 'Amelinho',
        LastName: 'Sparvsson',
        Email: 'amelinho.sparvsson@gmail.com',
        MobileNumber: '',
        PersonalID: '178901234768',
        Organization: 'Knowit',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: false,
        Read: true,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
        ],
      },
      {
        ID: 255,
        CreatedAt: '2022-06-02T19:50:33.900676Z',
        UpdatedAt: '2022-06-02T19:50:33.900676Z',
        DeletedAt: null,
        DocumentID: 210,
        FirstName: 'Verainho ',
        LastName: 'Uttersson',
        Email: 'test@test.test',
        MobileNumber: '0701740605',
        PersonalID: '178901235013',
        Organization: '',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: false,
        Read: false,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
        ],
      },
    ],
    SigningMessage: '',
    ForceSigningOrder: true,
    State: '',
    OrganizationIdentifier: 'knowit.signport.se',
  },
  {
    ID: 213,
    CreatedAt: '2022-06-21T19:50:33.806059Z',
    UpdatedAt: '2022-04-02T19:50:33.806059Z',
    DeletedAt: null,
    Author: {
      ID: 20,
      CreatedAt: '2022-06-15T09:13:06.570021Z',
      UpdatedAt: '2022-06-15T18:37:14.55818Z',
      DeletedAt: null,
      FirstName: 'Anro',
      LastName: 'Outo',
      Email: 'anroouto@gmail.com',
    },
    AuthorID: 20,
    DocumentURL: '/documentstorage/33.451910736Z-Minavtal (signed4).pdf;',
    DocumentMimetype: 'application/pdf',
    ExpirationDate: '0001-01-01T00:00:00Z',
    Permissions: 'all',
    ReminderMessageB64: Base64.encodeURI('sdf'),
    ReturnURL: '',
    Revoked: true,
    Signers: [
      {
        ID: 259,
        CreatedAt: '2022-06-02T09:13:06.795857Z',
        UpdatedAt: '2022-06-04T18:37:14.65296Z',
        DeletedAt: null,
        DocumentID: 209,
        FirstName: 'Amelinho',
        LastName: 'Sparvsson',
        Email: 'amelinho.sparvsson@knowit.se',
        MobileNumber: '',
        PersonalID: '178901234768',
        Organization: 'Knowit',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: true,
        Read: false,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
        ],
      },
      {
        ID: 259,
        CreatedAt: '2022-06-02T09:13:06.795857Z',
        UpdatedAt: '2022-06-04T18:37:14.65296Z',
        DeletedAt: null,
        DocumentID: 209,
        FirstName: 'Amelinho',
        LastName: 'Sparvsson',
        Email: 'amelinho.sparvsson3@knowit.se',
        MobileNumber: '',
        PersonalID: '178901234768',
        Organization: 'Knowit',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: false,
        Read: true,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
        ],
      },
      {
        ID: 259,
        CreatedAt: '2022-06-02T09:13:06.795857Z',
        UpdatedAt: '2022-06-04T18:37:14.65296Z',
        DeletedAt: null,
        DocumentID: 209,
        FirstName: 'Amelinho',
        LastName: 'Sparvsson',
        Email: 'amelinho.sparvsson4@knowit.se',
        MobileNumber: '',
        PersonalID: '178901234768',
        Organization: 'Knowit',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: false,
        Read: true,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
        ],
      },
      {
        ID: 255,
        CreatedAt: '2022-06-02T19:50:33.900676Z',
        UpdatedAt: '2022-06-02T19:50:33.900676Z',
        DeletedAt: null,
        DocumentID: 210,
        FirstName: 'Test',
        LastName: 'Testsson',
        Email: 'test2@test2.test',
        MobileNumber: '0701740605',
        PersonalID: '178901235013',
        Organization: '',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: false,
        Read: false,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
        ],
      },
      {
        ID: 259,
        CreatedAt: '2022-06-02T09:13:06.795857Z',
        UpdatedAt: '2022-06-04T18:37:14.65296Z',
        DeletedAt: null,
        DocumentID: 209,
        FirstName: 'Amelinho',
        LastName: 'Sparvsson',
        Email: 'amelinho.sparvsson5@knowit.se',
        MobileNumber: '',
        PersonalID: '178901234768',
        Organization: 'Knowit',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: false,
        Read: true,
        Rejected: true,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
        ],
      },
      {
        ID: 255,
        CreatedAt: '2022-06-02T19:50:33.900676Z',
        UpdatedAt: '2022-06-02T19:50:33.900676Z',
        DeletedAt: null,
        DocumentID: 210,
        FirstName: 'Test',
        LastName: 'Testsson',
        Email: 'test@test.test',
        MobileNumber: '0701740605',
        PersonalID: '178901235013',
        Organization: '',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: false,
        Read: false,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
        ],
      },
    ],
    SigningMessage: '',
    ForceSigningOrder: true,
    State: '',
    OrganizationIdentifier: 'knowit.signport.se',
  },
  {
    ID: 214,
    CreatedAt: '2022-05-21T19:50:33.806059Z',
    UpdatedAt: '2022-04-02T19:50:33.806059Z',
    DeletedAt: null,
    Author: {
      ID: 20,
      CreatedAt: '2022-06-15T09:13:06.570021Z',
      UpdatedAt: '2022-06-15T18:37:14.55818Z',
      DeletedAt: null,
      FirstName: '',
      LastName: 'Sparvsson',
      Email: 'amelinho.sparvsson2@hotmail.com',
    },
    AuthorID: 20,
    DocumentURL: '/documentstorage/33.451910736Z-Minavtal (signed4).pdf;',
    DocumentMimetype: 'application/pdf',
    ExpirationDate: '0001-01-01T00:00:00Z',
    Permissions: 'all',
    ReminderMessageB64: Base64.encodeURI('sdf'),
    ReturnURL: '',
    Revoked: false,
    Signers: [
      {
        ID: 255,
        CreatedAt: '2022-06-02T19:50:33.900676Z',
        UpdatedAt: '2022-06-02T19:50:33.900676Z',
        DeletedAt: null,
        DocumentID: 210,
        FirstName: 'Amelinho ',
        LastName: 'Sparvesson',
        Email: 'amelinho.sparvsson2@hotmail.com',
        MobileNumber: '0701740672',
        PersonalID: '178901234768',
        Organization: '',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: false,
        Read: false,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
        ],
      },
      {
        ID: 259,
        CreatedAt: '2022-06-02T09:13:06.795857Z',
        UpdatedAt: '2022-06-04T18:37:14.65296Z',
        DeletedAt: null,
        DocumentID: 209,
        FirstName: 'Amelinho',
        LastName: 'Sparvsson',
        Email: 'amelinhosparvsson@gmail.com',
        MobileNumber: '',
        PersonalID: '178901234768',
        Organization: 'Knowit',
        EIDASProvisionalID: '',
        EIDASPersonIdentifier: '',
        Signed: false,
        Read: false,
        Rejected: false,
        IdentityProviders: [
          {
            entityId: 'https://eid.legitimeringstjanst.se/sc/mobilt-bankid/',
            displayName: 'Mobilt BankID',
            displayName_en: 'Mobile BankID',
            requiredFields: ['urn:oid:1.2.752.29.4.13'],
            enabledForSigning: true,
          },
        ],
      },
    ],
    SigningMessage: '',
    ForceSigningOrder: false,
    State: '',
    OrganizationIdentifier: 'knowit.signport.se',
  },
];

export const caseMock = casesMock[0];

export const authorConnectionsMock = [
  { Email: 'test@test.test', ConnectedEmail: 'another@email.se' },
  { Email: 'emailThatHasDelegatedToMe@email.se', ConnectedEmail: 'test@test.test' },
];

export const delegatedCasesMock = [
  casesMock[0],
  casesMock[1],
  casesMock[2],
  casesMock[4],
  casesMock[5],
];

// export const fetchDocs = () =>
//   new Promise((resolve) => setTimeout(() => resolve(prepareDocuments(casesMock)), 600));

// this will be a mock post function that will push and a new signing to the array
export const postDoc = (ctx) =>
  new Promise((resolve) => setTimeout(() => resolve(console.log('in mock', ctx)), 600));
